
.wrapper {
  min-height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
  padding-bottom: var(--footer-height);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.header {
  position: fixed;
  top: 0px;
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--grey);
  background-color: var(--white);
  z-index: 999;
}

.logo {
  height: 80%;
  object-fit: contain;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

.panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}

.panelTitle {
  font-size: 2rem;
  font-weight: 900;
  color: var(--white);
  margin-bottom: 1rem;
}

.panelArt {
  height: 25vh;
}

.title {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
}

.subTitle {
  color: var(--primary);
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.searchWidget {
  width: 100%;
  padding: 1.5rem;
  max-width: 500px;
}

.fieldWrapper {
  margin-top: 1rem;
}

.materialField {
  width: 100%;
}

.results {
  width: 100%;
  border-top: 1px solid var(--grey);
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resultOccupationPanel {
  max-width: 500px;
  width: 100%;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--grey);
}

.resultName {
  font-size: 1.5rem;
  color: var(--text);
  margin-bottom: 0.5rem;
}

.resultCode {
  font-weight: 300;
  color: var(--primary);
}

.visaList {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  padding: 1rem;
}

.visa {
  width: 100%;
  padding: 1.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--shadow);
}

.visaName {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 900;
  margin-right: 1rem;
}

.visaListType {
  padding-top: 0.5rem;
  font-weight: 500;
  color: var(--secondary);
}

.visaInfo {
  padding-top: 1rem;
  color: var(--light-text);
  padding-bottom: 1rem;
}

.documentIcon {
  color: var(--text);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: var(--footer-height);
  background-color: var(--dark);
}

.disclaimer {
  font-weight: 300;
  color: var(--light-text);
  padding: 1rem;
  width: 100%;
}

.footerName {
  color: var(--grey);
}

.highlight {
  color: var(--secondary);
}