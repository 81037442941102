* {
  box-sizing: border-box;
}

:root {
  --header-height: 10vh;
  --footer-height: 10vh;
  --text: #444649;
  --light-text: #777777;
  --black: #121212;
  --dark: #040037;
  --white: #ffffff;
  --grey: #dddddd;
  --primary: #4D40FF;
  --secondary: #E21E51;
  --shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
